import React from "react";
import Header from "../../components/header";
import { LightBtn } from "../../components/button";
import Footer from "../../components/footer";

const Policy = () => {
  return (
    <div>
      <Header />
      <Hero />
      <MainSection />
      <Cta />
      <Footer />
    </div>
  );
};
const Hero = () => {
  return (
    <section className="tw-w-full policyBg tw-flex tw-items-center tw-justify-center tw-bg-[#F2FAFD] tw-h-screen">
      <div className="container">
        <h1 className="lg:tw-text-6xl tw-text-5xl rowdies tw-font-normal tw-text-main lg:tw-leading-[65px]">
          Our Commitment to
          <br /> Your Privacy
        </h1>
        <p className="tw-text-xl tw-pt-8 lg:tw-pt-0 tw-font-medium tw-max-w-2xl tw-text-main manrope tw-leading-[34px]">
          Safeguarding Your Personal Information and Ensuring Data Security. Ac
          dui duis ridiculus purus magnis porta vitae vitae. Lobortis iaculis
          euismod tincidunt orci nunc at faucibus. Amet in tempor odio.
        </p>
      </div>
    </section>
  );
};

const MainSection = () => {
  return (
    <section className="tw-bg-white tw-flex tw-justify-center tw-py-20 tw-w-full">
      <div className="tw-space-y-6 container">
        <p className="manrope tw-text-base tw-font-medium lg:tw-max-w-2xl tw-text-main">
          Lorem ipsum dolor sit amet consectetur. Nec tellus viverra neque neque
          pellentesque. Scelerisque metus vivamus placerat egestas mauris elit.
          Posuere tortor gravida cursus ultrices. Justo fermentum aliquam
          pellentesque nisl in blandit purus nibh. Etiam lectus eros id feugiat
          amet. Habitasse at sed mauris massa. Ornare est dolor augue orci
          facilisis adipiscing eleifend fermentum. Felis nibh pulvinar amet
          morbi integer vel integer. Proin turpis turpis orci et. Tortor mauris
          nisl tincidunt mauris auctor cursus. Quis lacus tortor elementum ipsum
          mauris arcu elementum etiam diam.
        </p>
        <p className="manrope tw-text-base tw-font-medium tw-max-w-2xl tw-text-main">
          Lorem ipsum dolor sit amet consectetur. Nec tellus viverra neque neque
          pellentesque. Scelerisque metus vivamus placerat egestas mauris elit.
          Posuere tortor gravida cursus ultrices. Justo fermentum aliquam
          pellentesque nisl in blandit purus nibh. Etiam lectus eros id feugiat
          amet. Habitasse at sed mauris massa. Ornare est dolor augue orci
          facilisis adipiscing eleifend fermentum. Felis nibh pulvinar amet
          morbi integer vel integer. Proin turpis turpis orci et. Tortor mauris
          nisl tincidunt mauris auctor cursus. Quis lacus tortor elementum ipsum
          mauris arcu elementum etiam diam.
        </p>
        <p className="manrope tw-text-base tw-font-medium tw-max-w-2xl tw-text-main">
          Lorem ipsum dolor sit amet consectetur. Nec tellus viverra neque neque
          pellentesque. Scelerisque metus vivamus placerat egestas mauris elit.
          Posuere tortor gravida cursus ultrices. Justo fermentum aliquam
          pellentesque nisl in blandit purus nibh. Etiam lectus eros id feugiat
          amet. Habitasse at sed mauris massa. Ornare est dolor augue orci
          facilisis adipiscing eleifend fermentum. Felis nibh pulvinar amet
          morbi integer vel integer. Proin turpis turpis orci et. Tortor mauris
          nisl tincidunt mauris auctor cursus. Quis lacus tortor elementum ipsum
          mauris arcu elementum etiam diam.
        </p>
        <p className="manrope tw-text-base tw-font-medium tw-max-w-2xl tw-text-main">
          Lorem ipsum dolor sit amet consectetur. Nec tellus viverra neque neque
          pellentesque. Scelerisque metus vivamus placerat egestas mauris elit.
          Posuere tortor gravida cursus ultrices. Justo fermentum aliquam
          pellentesque nisl in blandit purus nibh. Etiam lectus eros id feugiat
          amet. Habitasse at sed mauris massa. Ornare est dolor augue orci
          facilisis adipiscing eleifend fermentum. Felis nibh pulvinar amet
          morbi integer vel integer. Proin turpis turpis orci et. Tortor mauris
          nisl tincidunt mauris auctor cursus. Quis lacus tortor elementum ipsum
          mauris arcu elementum etiam diam.
        </p>
      </div>
    </section>
  );
};
export const Cta = () => {
  return (
    <section className="ctaBg1 tw-w-full tw-py-16">
      <div className="container tw-justify-center tw-gap-20 tw-flex tw-flex-col lg:tw-flex-row tw-items-center">
        <div className="">
          <h3 className="tw-text-4xl tw-max-w-md rowdies tw-font-normal tw-text-white">
            Ready to embark on a journey of smart mobility transformation?
          </h3>
        </div>
        <div className="">
          <p className="manrope tw-max-w-md tw-text-base tw-text-[#F2F4F5] tw-font-normal">
            Discover how our innovative software products can propel your
            business forward and revolutionize the way you move. Contact us
            today to schedule a personalized consultation or explore our
            portfolio online.
          </p>
          <div className="tw-mt-8">
            <LightBtn text={"Contact us"} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Policy;
