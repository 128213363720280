import React, { useEffect } from "react";
import LogoImg from "../assets/zeno.jpg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Car from "../assets/car.svg";
import Carblue from "../assets/car2.svg";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Appstore from "../assets/appstore.svg";
import Playstore from "../assets/playstore.svg";
import { useSelector } from "react-redux";
import { MainPaginate } from "./button";

const Header = () => {
	const [showProducts, setShowProducts] = useState(false);
	const [nav, setNav] = useState(false);
	const navigate = useNavigate();

	const navItem = [
			{
				name: "advocacy",
				action: () => {},
			},
			{
				name: "products",
				action: () => {
					setShowProducts(!showProducts);
				},
			},
			{
				name: "about us",
				action: () => navigate("/about"),
			},
		],
		productsArr = [
			{
				title: "Zeno Smart Mobility",
				description:
					"A smarter way to move. Eco-friendly, smart and efficient ride-sharing solution for a greener future.",
			},
			{
				title: "Vehicle maintenance & Repair Portal",
				description:
					"Manage repair requests, automate maintenance schedules and get insights on vehicle health and progress on one comprehensive dashboard.",
			},
			{
				title: "Driver Management System",
				description:
					"One comprehensive dashboard to onboard drivers, manage earnings, track progress and gain insights into your mobility ecosystem.",
			},
			{
				title: "Invest in Mobility Asset  ",
				description:
					"Simplified crowd-funding solution, for income growth through mobility assets.",
			},
			{
				title: "Digitize your public mass mobility",
				description:
					"Smart technological solution for managing public mass mobility systems. Schedule routes, sign up users, and manage payments with ease.",
			},
		],
		[productState, setState] = useState({ docs: productsArr }),
		{ data } = useSelector(s => s);

	useEffect(() => {
		if (data?.product?.docs?.length > 0) {
			setState(data?.product);
		}
	}, [data?.product]);

	// let [page, setPage] = useState(1);

	let [range] = useState(9);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = productState?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(productState?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % productState?.docs?.length;
		setItemOffset(newOffset);
		// setPage(1 + event?.selected);
	};

	return (
		<div className="tw-relative">
			<div className="tw-w-full tw-h-20 tw-bg-white">
				<div className="container tw-flex tw-justify-between tw-items-center tw-h-full">
					<Logo />
					<div onClick={() => setNav(!nav)} className="md:tw-hidden tw-block">
						{nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
					</div>
					<div className="md:tw-flex tw-hidden tw-gap-5">
						{navItem.map(item => (
							<div
								onClick={item.action}
								className="tw-w-32 tw-h-10 hover:tw-bg-slate-100 tw-flex tw-items-center tw-justify-center tw-cursor-pointer">
								<h2 className="tw-text-base tw-cursor-pointer manrope tw-font-normal tw-uppercase tw-text-main">
									{item.name}
								</h2>
							</div>
						))}
					</div>
				</div>
				{nav && (
					<div className="tw-fixed tw-w-full tw-bg-main tw-h-72 tw-z-[1000] tw-pl-8 tw-pt-8">
						{navItem.map(item => (
							<h2
								onClick={item.action}
								className="tw-text-base tw-cursor-pointer manrope tw-font-normal tw-uppercase tw-text-white">
								{item.name}
							</h2>
						))}
						<div className="tw-mt-8 tw-flex tw-gap-5">
							<img src={Appstore} alt="" className="" />
							<img src={Playstore} alt="" className="" />
						</div>
					</div>
				)}
			</div>
			{showProducts && (
				<div
					style={{
						boxShadow: "0px 51px 153px -32px rgba(0, 0, 0, 0.10)",
					}}
					className="tw-absolute tw-z-[1000] tw-top-20 tw-w-full tw-py-6 tw-bg-white">
					<div className="container">
						<h2 className="tw-text-base manrope tw-text-secondary tw-font-medium tw-uppercase">
							products
						</h2>
						<div className="tw-mt-6 tw-grid lg:tw-grid-cols-3 tw-gap-3">
							{currentItems?.map((item, i) => (
								<Product
									item={item}
									key={i}
									defaultImage={Car}
									hoverImage={Carblue}
								/>
							))}
						</div>
						{pageCount > 1 && (
							<MainPaginate
								pageCount={pageCount}
								handlePageClick={handlePageClick}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export const Product = ({ item, defaultImage, hoverImage }) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className="tw-flex tw-h-full hover:tw-bg-slate-200 tw-p-3 tw-items-center tw-gap-5 tw-cursor-pointer">
			{isHovered ? (
				<img src={hoverImage} alt="" className="" />
			) : (
				<img src={defaultImage} alt="" className="" />
			)}
			<div>
				<h6 className="tw-text-sm tw-text-main tw-font-bold manrope">
					{item?.title}
				</h6>
				<p className="tw-text-sm tw-text-secondary tw-font-normal manrope">
					{item?.description}
				</p>
			</div>
		</div>
	);
};
export const Logo = () => {
	const navigate = useNavigate();
	return (
		<>
			<img
				onClick={() => navigate("/")}
				src={LogoImg}
				alt=""
				className="tw-h-12 tw-cursor-pointer"
			/>
		</>
	);
};
export default Header;
