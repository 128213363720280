import React from "react";
import Header from "../../components/header";
import Img from "../../assets/details1.png";
import Img2 from "../../assets/details2.png";
import Img3 from "../../assets/details3.png";
import Threebox from "../../components/threebox";
import MyBtn from "../../components/button";
import { useNavigate } from "react-router-dom";
import Event1 from "../../assets/event.png";
import Event2 from "../../assets/event2.png";
import Event3 from "../../assets/events.png";
import Footer from "../../components/footer";

const EventDetails = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Events />
      <Footer />
    </div>
  );
};

const MainSection = () => {
  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="container">
        <h1 className="myHeader lg:tw-text-center">
          Designing for the Future: Tips and Tricks
        </h1>
        <h2 className="lg:tw-text-center tw-pt-2 tw-text-main tw-text-base tw-font-medium tw-uppercase">
          Jan 24, 2023 • Category 1
        </h2>
        <img src={Img} alt="" className="tw-my-6" />
        <div className="lg:tw-w-2/3 tw-mx-auto">
          <div className="tw-space-y-4">
            <p className="tw-text-main manrope lg:tw-text-xl tw-text-base tw-font-normal tw-leading-8">
              Hey guys! Are you one of those people who get overwhelmed with the
              countless amount of design trends and techniques out there? Well,
              don't worry, you're not alone! Designing can be daunting,
              especially when you're aiming for a future-proof solution. But
              worry not, in this post, I'm going to give you some tips and
              tricks you can use to make your design stand out from the rest
              today and tomorrow.
            </p>
            <p className="tw-text-main manrope lg:tw-text-xl tw-text-base tw-font-normal tw-leading-8">
              First off, let's talk about colors. Pick colors that have
              longevity, meaning colors that aren't dependent on trends. Colors
              like black, white, gray, and neutrals can be paired with any other
              shade and still look fabulous. It's essential to consider how the
              colors work together to ensure they complement the overall brand
              message.
            </p>
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-8">
            <img src={Img2} alt="" className="" />
            <img src={Img3} alt="" className="" />
          </div>
          <div className="tw-space-y-4">
            <p className="tw-text-main manrope lg:tw-text-xl tw-text-base tw-font-normal tw-leading-8">
              Second, pay close attention to typography. A well-curated font
              choice can make or break your design. Choose fonts that are
              legible, versatile, and cohesive across all of your design pieces.
              You'll need to find fonts that convey the right tone and message.
            </p>
            <p className="tw-text-main manrope lg:tw-text-xl tw-text-base tw-font-normal tw-leading-8">
              Third, prioritize user experience. Design for simplicity, ease of
              use, and accessibility. You want your design to be accessible and
              easy to understand for all types of users, regardless of their
              experience with technology. Utilize white space, summarize
              content, and make it easy for users to find what they need.
            </p>
            <p className="tw-text-main manrope lg:tw-text-xl tw-text-base tw-font-normal tw-leading-8">
              Finally, always plan ahead for emerging technologies. Design with
              the future in mind, knowing that the technology that we have today
              will change. Keeping your website up to date with cutting edge
              design is imperative in staying relevant and ideal for your
              readers. Planning for emerging technologies will keep your design
              ahead of the curve.
            </p>
            <p className="tw-text-main manrope lg:tw-text-xl tw-text-base tw-font-normal tw-leading-8">
              So there you have it - some quick tips and tricks for creating a
              future-proof design! Keep these points in mind, and you're sure to
              stay ahead of the curve. Thanks for stopping by, and I hope to see
              you soon for more content on web design!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
const Events = () => {
  const navigate = useNavigate();
  const Arr = [
    {
      image: Event1,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event2,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
    {
      image: Event3,
      title:
        "The Definitive Guide to Revenue Operations: What It Is and Why It Matters",
      date: "jan 24",
      category: "1",
    },
  ];
  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="container">
        <h3 className="myHeader">Read other interesting posts</h3>
        <Threebox Arr={Arr} />
        <div className="tw-mt-10 tw-flex tw-justify-center">
          <MyBtn text={"See other Events"} onClick={() => navigate("/blogs")} />
        </div>
      </div>
    </section>
  );
};
export default EventDetails;
