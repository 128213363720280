import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Threebox = ({ Arr, link, currentItem }) => {
	let navigate = useNavigate(),
		[state, setState] = useState(null);

	useEffect(() => {
		setState(currentItem || Arr?.docs);
	}, [currentItem, Arr]);

	return (
		<div className="tw-w-full tw-grid lg:tw-grid-cols-3 tw-gap-6 tw-mt-10">
			{state?.map((item, i) => (
				<div
					data-aos="fade-down"
					data-aos-duration="1500"
					key={i}
					onClick={() => navigate(`/blogs/blog-details`, { state: item })}
					className="tw-cursor-pointer tw-overflow-hidden tw-w-full [&>*:nth-child(2)]:hover:tw-underline [&>*:nth-child(2)]:hover:tw-font-semibold hover:tw-scale-100 tw-scale-90 tw-transform tw-transition-transform tw-ease-in-out tw-duration-500">
					<div className="tw-relative tw-w-full tw-h-56 tw-overflow-hidden   tw-rounded-xl">
						<img
							src={item?.image?.url || item?.image}
							alt=""
							className="tw-rounded-xl tw-h-full tw-w-full tw-scale-100 tw-transform tw-transition-transform tw-ease-in-out tw-duration-500  "
						/>
					</div>
					<h4 className="tw-text-base tw-pt-3 tw-fo nt-medium manrope tw-text-main tw-text-ellipsis">
						{item?.title}
					</h4>
					<p className="tw-text-secondary tw-pt-5 tw-font-normal tw-uppercase tw-text-base">
						{item?.createdAt
							? moment(item?.createdAt).format("MMM DD")
							: item?.date}
						. {item?.category?.title || item?.category}
					</p>
				</div>
			))}
		</div>
	);
};

export default Threebox;
